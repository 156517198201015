import React, {useState} from 'react'
import { Link, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import LanguageSwitcher from './LanguageSwitcher'
import logo from '../images/logo.png'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import styled from 'styled-components'

const StyledNavbar = styled(Navbar)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

span.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

background-color: ${props => props.expanded ? "#a3ddff" : "rgba(93,119,162,0.5)"};

.navbar-nav {
  // Space of the first nav item to top
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.dropdown-menu{
  background-color: #cbecff;
}

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {

}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {

  .navbar-nav {
    // Space of the first nav item to top
    margin-top: initial;
    padding-bottom: initial;
  }

  .dropdown-menu{
    background-color: rgba(93,119,162,0.5);
    margin-top: 7px !important;
  }
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}
`;

const StyledNavbarToggle = styled(Navbar.Toggle)`
  background-color: transparent;
  border: 0;

  &:focus {
    outline: none !important;
    border: 0 !important;
  }
`;

const TopMenu = ({ topMenu, activeDocMeta }) => {

  const [expanded, setExpanded] = useState(false);

  // console.log('dropdown links', topMenu.body[0].items);
  // console.log('topMenu', topMenu);

/*   const renderedMenuLinks = topMenu.menu_links
    ? topMenu.menu_links.map((menuLink, index) => (
      <li key={`top-nav-${index}`}>
        <Link id={menuLink.link.id} to={menuLink.link.url}>
          {RichText.asText(menuLink.label.raw)}
        </Link>
      </li>
    ))
    : null */

  return (
    <header>
{/*       <div className="menu">
        <Link to="/">
          <img className="logo" src={logo} alt="Site logo" />
        </Link>
      </div>
      <div className="menu">
        <ul>
          {renderedMenuLinks}
          <LanguageSwitcher activeDocMeta={activeDocMeta} />
        </ul>
      </div> */}
      <StyledNavbar expand="lg" expanded={expanded}>
        <Container fluid>
          {/* Without Link does page reload */}
          {/* <Navbar.Brand href="/">React-Bootstrap</Navbar.Brand> */}
          {/* <Link to="/"> */}
          {/* Hide Brand in Mobile View:  className="d-none d-md-block" */}
            {<Navbar.Brand style={{marginLeft: "5vw", color: "black"}}>{topMenu.brand}</Navbar.Brand>}
          {/* </Link> */}
          <StyledNavbarToggle className="ms-auto" aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* <Nav.Link>
                <Link to="/">
                  Home
                </Link>
              </Nav.Link> */}
              {/* <Nav.Link>{renderedMenuLinks}</Nav.Link> */}
              {/* <Nav.Item style={{ marginRight: "5vw" }}>
              <Nav.Link>
                <LanguageSwitcher activeDocMeta={activeDocMeta} />
              </Nav.Link>
              </Nav.Item> */}
              <Nav.Item style={{ marginRight: "5vw" }}>
              <Nav.Link href="mailto:hegland@bluewin.ch">
                Contact
              </Nav.Link>
              </Nav.Item>
              <NavDropdown style={{ marginRight: "5vw" }} title={topMenu.body[0].primary.dropdown_text} id="basic-nav-dropdown">
                {topMenu.body[0].items.map((dropdownItem, index) => (
                  <NavDropdown.Item 
                  key={`top-nav-dropdown-item-${index}`} 
                  href={dropdownItem.link_url.url}
                  target="_blank"
                  >
                    {dropdownItem.link_lable}
                  </NavDropdown.Item>  
                ))}
                {/* <NavDropdown.Item href="https://drive.google.com/file/d/1F7NNIh_PteMWVWxfN3T3BFkzS12hpUxw/view?usp=sharing" target="_blanck">Egyptian</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
    </header>
  )
}

export const query = graphql`
fragment TopMenuFragment on PrismicTopMenu {
  type
  lang
  data {
    body {
      ... on PrismicTopMenuBodyDropdownNav {
        id
        items {
          link_lable
          link_url {
            url
            uid
          }
        }
        slice_type
        primary {
          dropdown_text
        }
      }
    }
    brand
    menu_links {
      label {
        raw
        html
        text
      }
      link {
        id
        url
      }
    }
  }
}
`

export default TopMenu
