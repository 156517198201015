import styled from 'styled-components'
import Card from 'react-bootstrap/Card'

const StyledCard = styled(Card)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap
    margin-bottom: 1.8rem !important;

    // determins breakepoint
    min-width: 80vw;

    .card-img{
        border-radius: 1rem;
    }

    a.card-link {
        display: none;
    }

    border-radius: 1rem;

    // Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {


}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {
}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    
    a.card-link {
        display: block;
    }

    .no-modal {
        display: none;
    }

    margin-bottom: 0 !important;

    // determins breakepoint
    min-width: 20vw;

}
    `
export default StyledCard