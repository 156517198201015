import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

const StyledCarousel = styled(Carousel)`
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -5rem;
`;

const imagePortrait = "w-50";
const imageLandscape = "w-100";

const calcAspectRatio = (width, height) => width/height;

export default function Carousell({ images, activeIndex }) {
    console.log('images', images);
    for (let index = 0; index < images.length; index++) {
        const element = images[index];
        element.image.ar = calcAspectRatio(element.image.dimensions.width, element.image.dimensions.height);
    }

    return (
        <StyledCarousel fade>
        {/* <StyledCarousel activeIndex={activeIndex} fade> */}
            {images.map((image, i) => (
                <Carousel.Item key={i}>
                    <a href={image.link}>
                            <img
                            className={image.image.ar < 1 ? imagePortrait : imageLandscape + " d-block"}
                                srcSet={`${image.image.url} ${image.image.dimensions.width}w,
                                ${image.image.thumbnails.phone_landscape.url} ${image.image.thumbnails.phone_landscape.dimensions.width}w,                                
                                ${image.image.thumbnails.tablet.url} ${image.image.thumbnails.tablet.dimensions.width}w,
                                ${image.image.thumbnails.laptop.url} ${image.image.thumbnails.laptop.dimensions.width}w
                                `}
                                src={image.image.url}
                                alt={image.alt}
                            />
                    </a>
                    <Carousel.Caption>
                        <RichText render={image.caption_title} />
                        <RichText render={image.caption_text} />
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </StyledCarousel>
    )
}
