import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'
import {Link} from 'gatsby'

const HeroWrapper = styled.div`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap
  background: url("${props => props.bgImage}");
  /* 56px = hight of navbar */
  height: calc(100vh - 0px);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  /* less than 12px shows margin, more shows vertical scroll */
  margin: -64px -12px 0 -12px;

    div{
      max-width: 800px; 
      margin: 0 10%;
      background: rgba(93,119,162,0.5);
      padding: 5vw 5vw;
      border-radius: 30px;
    }

    div p{
      font-size: 1.8em;
      margin-top: 2em;
      margin-bottom: 1.5em;
    }
// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
  background: url("${props => props.bgImagePhoneLandscape}");
}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {
  background: url("${props => props.bgImageTablet}");
}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
  background: url("${props => props.bgImageLaptop}");
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}
`;

const StyledLink = styled(Link)`

    background: transparent;
    color: white;
    padding: 15px 30px; 
    border-radius: 15px;
    font-size: 2em;
    border: 2px solid;
    text-decoration: none;
    display: inline-block;
    text-align: center;

    &:hover{
      color: #bd0000;
      text-decoration: none;
    }
`;

const Startbildschirm = ({ slice }) => {
    // console.log("startbildschirm slice", slice);

    const bgImage = slice.primary.background_image.url;
    const bgImageLaptop = slice.primary.background_image.thumbnails.laptop.url;
    const bgImageTablet = slice.primary.background_image.thumbnails.tablet.url;
    const bgImagePhoneLandscape = slice.primary.background_image.thumbnails.phone_landscape.url;
    return (
        <section>
            {/* <div>
            <RichText render={slice.primary.hero_title.raw || []} />
        </div>
        <Button variant="primary">Button</Button> */}

            {/* <HeroWrapper> */}
                <HeroWrapper 
                bgImage={bgImage}
                bgImageLaptop={bgImageLaptop}
                bgImageTablet={bgImageTablet}
                bgImagePhoneLandscape={bgImagePhoneLandscape}
                >
                <div>
                    <RichText render={slice.primary.hero_title.raw || []} />
                    <p>
              {slice.primary.hero_content}
                    </p>
            {/* <StyledLink to={`${slice.primary.cta_link.url}`} target="_blank" >{slice.primary.hereo_cta_button_label}</StyledLink> */}
                </div>
            </HeroWrapper>
        </section>
    )
}

export default Startbildschirm
