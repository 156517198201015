import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import Card from 'react-bootstrap/Card'
import Carousel from '../Carousel'
import StyledModal from '../StyledModal'
import StyledCard from '../StyledCard'
import Modal from 'react-bootstrap/Modal'
import { Row, Col, Container } from 'react-bootstrap'

const Bildergalerie = ({ slice }) => {
    // console.log("BildergalerieSlice", slice);

    let [activeIndex, setActiveIndex] = useState(0);

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (i) => {
        console.log('img in handleShow 1', i);
        setShow(true);
        setActiveIndex(activeIndex = i);
    }
    // End Modal

    return (
        <>
            <Row className="mt-4 mt-md-2 mb-4 mb-md-2">
                <Col md={1} lg={2}></Col>
                <Col md={10} lg={8}>
                    <RichText render={slice.primary.section_title.raw || []} />
                </Col>
                <Col md={1} lg={2}></Col>
            </Row>
            <Row className="mt-4 mt-md-2 mb-4 mb-md-2">
                <Col md={1} lg={2}></Col>
                <Col md={10} lg={8}>
                    <Row>
                        {slice.items.map((image, i) => (
                            <Col>
                                <StyledCard key={i}>
                                    <Card.Link onClick={() => { handleShow(i) }}>
                                        <Card.Img
                                            srcSet={`${image.image.url} ${image.image.dimensions.width}w,
                                                        ${image.image.thumbnails.phone_landscape.url} ${image.image.thumbnails.phone_landscape.dimensions.width}w,                                
                                                        ${image.image.thumbnails.tablet.url} ${image.image.thumbnails.tablet.dimensions.width}w,
                                                        ${image.image.thumbnails.laptop.url} ${image.image.thumbnails.laptop.dimensions.width}w
                                                `}
                                            src={image.image ? image.image.url : null} />
                                    </Card.Link>
                                    <Card.Img className="no-modal" src={image.image ? image.image.url : null} />
                                </StyledCard>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col md={1} lg={2}></Col>
            </Row>
            <StyledModal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <Container>
                        <Modal.Header closeButton />
                        <Row>
                            <Col align="center">
                                <Carousel images={slice.items} activeIndex={activeIndex} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </StyledModal>
        </>
    )
}

export default Bildergalerie
