import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import { Row, Col } from 'react-bootstrap'

const TextBlock = ({ slice }) => {
    // console.log("TextBlockSlice", slice);

    return (
        <section>
            <Row className="mt-4 mt-md-5 mb-4 mb-md-5">
                <Col md={1} lg={2}></Col>
                <Col md={10} lg={8}>
                    <RichText render={slice.items[0].text.raw || []} />
                </Col>
                <Col md={1} lg={2}></Col>
            </Row>
        </section>
    )
}

export default TextBlock
