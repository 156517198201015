import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
background: #a9c5b1;
margin-top: 15vw;

a{
  color: #718075;
  &:hover{
    color: #5b9269;
  }
}
`;

const Footer = () => (
  <footer>
    <StyledContainer fluid>
      <Row>
        <Col className="mt-5" align="center">
        <h4>Contact:</h4>
        <address style={{fontSize: "1.2rem"}}>Dr.med.Ingvar Hegland, Buchserstrasse 7, 8157 Dielsdorf, Switzerland  <a href="mailto:hegland@bluewin.ch">hegland@bluewin.ch</a>
</address>
        </Col>
      </Row>
      <Row>
        <Col md={1} lg={2}></Col>
        <Col md={10} lg={8}>
          <Row className="mb-1 mt-5">
            <Col xs={12} md={6} align="center">
              <p style={{fontSize: "0.9rem"}}>Website made by <a href="https://klap-web.ch/" target="_blank">klap-web.ch</a></p>
            </Col>
            <Col md={6} align="center">
              <p style={{fontSize: "0.9rem"}} className="copyright">© 2021 Ingvar Hegland</p>
            </Col>
          </Row>
        </Col>
        <Col md={1} lg={2}></Col>
      </Row>
    </StyledContainer>
  </footer>
)

export default Footer
