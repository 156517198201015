import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'

const FullWidthImage = ({ slice }) => {

  return (
    <section>
      <Row className="mt-4 mt-md-2 mb-4 mb-md-2">
        <Col align="center">
        <Image fluid
          src={slice.primary.image ? slice.primary.image.url : ''}
          alt={slice.primary.image ? slice.primary.image.alt : ''}
        />
        </Col>
      </Row>
    </section>
  )
}

export default FullWidthImage
