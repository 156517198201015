import React from 'react'
import { Row, Col } from 'react-bootstrap'

function GoogleMap() {
    return (
        <section>
        <Row className="mt-4 mt-md-2 mb-4 mb-md-2">
            <Col md={1} lg={2}></Col>
            <Col md={10} lg={8}>
                <div>
                <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2747.078214196122!2d9.832807051382867!3d46.48676367254711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47837de782a9ee0f%3A0xd2738449e3aaf571!2sVia%20Giovanni%20Segantini%201%2C%207500%20St.%20Moritz!5e0!3m2!1sde!2sch!4v1624376110176!5m2!1sde!2sch"
                width="380" 
                height="380" 
                style={{ border: "0", position: 'relative', top: '0', left: '0', width: '100%', height: '100% !important'}} 
                allowfullscreen 
                loading="lazy"
                >
                </iframe>
                </div>
            </Col>
            <Col md={1} lg={2}></Col>
        </Row>
        </section>
    )
}

export default GoogleMap